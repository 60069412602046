<template>
  <div class="project-groups-responsible">
    <prj-header>
      <el-button type="primary" @click="addItemHandle">Добавить ответственного</el-button>
    </prj-header>

    <el-table
        :data="tableData"
        :default-sort="{ prop: 'pm_id', order: 'ascending' }"
        style="width: 100%">
      <el-table-column prop="pm_id" sortable label="Менеджер">
        <template slot-scope="scope">
          <router-link :to="{ name: keyWord, params: { recId: scope.row.id }, query: $route.query }">
            {{ pmName(scope.row.pm_id) }}
          </router-link>
        </template>
      </el-table-column>
      <el-table-column prop="pm_id" sortable label="Группа проектов">
        <template slot-scope="scope">
          <router-link :to="{ name: keyWord, params: { recId: scope.row.id }, query: $route.query }">
            {{ projectGroupName(scope.row.group_id) }}
          </router-link>
        </template>
      </el-table-column>
      <el-table-column prop="begin_date_iso" label="Дата начала" :formatter="formatterRuDate" />
      <el-table-column prop="end_date_iso" label="Дата окончания" :formatter="formatterRuDate" />
      <el-table-column label="">
        <template slot-scope="scope">
          <a class="el-icon-delete" @click="deleteHandle(scope.row.id, 'ответственный')" />
        </template>
      </el-table-column>
    </el-table>

    <project-group-responsible
      v-model="editRecord"
      @cancel="cancelHandle"
      v-if="editRecord !== null"/>
  </div>
</template>

<script>
import MultiPage from '@/mixins/multi-page.js'
import { roleNames } from '@/components/utils.js'
import Project from "@/views/Project.vue";

export default {
  store: ['pmList', 'projectGroupList'],

  mixins: [MultiPage],

  components: {ProjectGroupResponsible: () => import('./ProjectGroupResponsible.vue') },

  data() {
    return {
      keyWord: 'project-responsible',
      roleNames: roleNames,
      defaultRecord: {
        pm_id: '',
        group_id: '',
        begin_date_iso: '',
        end_date_iso: '',
      }
    }
  },

  methods: {

    pmName (pm_id) {
      for (let i = 0; i < this.pmList.length; i++) {
        const element = this.pmList[i]
        if (element.value == pm_id) {
          return element.label
        }
      }
      return ''
    },

    projectGroupName (group_id) {
      for (let i = 0; i < this.projectGroupList.length; i++) {
        const element = this.projectGroupList[i]
        if (element.value == group_id) {
          return element.label
        }
      }
      return ''
    },
  }
}
</script>
