<template>
  <prj-detail class="project" :title="mutableValue.name" @esc="cancelHandle">
    <dl>
      <dt>Наименование:</dt>
      <dd>{{mutableValue.name}}</dd>

      <dt>Redmine ID:</dt>
      <dd>{{mutableValue.identifier}}</dd>

      <template v-if="mutableValue.created_on">
        <dt>Дата создания в трекере:</dt>
        <dd>{{mutableValue.created_on_iso}}</dd>
      </template>

      <dt>Статус:</dt>
      <dd>{{projectStatus(mutableValue.is_public)}}</dd>

      <template v-if="mutableValue.description">
        <dt>Описание:</dt>
        <dd>{{mutableValue.description}}</dd>
      </template>

      <template v-if="mutableValue.project_group_name">
        <dt>Группа проектов:</dt>
        <dd>{{mutableValue.project_group_name}}</dd>
      </template>
    </dl>
  </prj-detail>
</template>

<script>
import DetailPage from '@/mixins/detail-page.js'
import { roleOptions } from '@/components/utils.js'

export default {
  mixins: [DetailPage],

  data() {
    return {
      roleOptions: roleOptions,
    }
  },

  methods: {
    projectStatus (is_public) {
      return is_public ? 'Активный': 'В архиве'
    }
  }
}
</script>

<style lang="less">
@import "../style.less";

.projects {
  .project-filter-button {
    margin-left: 15px;
  }
  .prj-detail-wrap {
    width: 600px;
  }
}
</style>