export default {
  props: {
    recId: {
      type: Number
    }
  },

  data () {
    return {
      keyWord: '',
      filterParams: {},
      tableData: [],
      totalRecords: 0,
      loading: true
    }
  },

  created () {
    this.loadTableData()
  },

  computed: {
    editRecord: {
      get: function () {
        if (this.recId === 0) {
          return this.defaultRecord
        } else if (this.recId > 0) {
          for (let i = 0; i < this.tableData.length; i++) {
            if (this.tableData[i].id === this.recId) {
              return this.tableData[i]
            }
          }
        }
        return null
      },

      set: function (val) {
        this.saveHandle(val)
      }
    }
  },

  methods: {
    getFirstDateOnPreviousMonth () {
      let date = new Date()
      date.setDate(1)
      date.setMonth(date.getMonth() - 1)
      return date
    },

    getLastDateOnPreviousMonth () {
      let date = new Date()
      date.setDate(0)
      return date
    },

    getDefaultFilterDates () {
      let from_date = this.getFirstDateOnPreviousMonth()
      let to_date = this.getLastDateOnPreviousMonth()
      return {
        from_date: from_date.toISOString().split('T')[0],
        to_date: to_date.toISOString().split('T')[0],
      }
    },

    formatterMoney: function(row, col, cell, index) {
        return this.$options.filters.toCurrency(cell)
    },

    formatterRuDate: function(row, col, cell, index) {
        return this.$options.filters.isoToRuDate(cell)
    },

    addItemHandle () {
      this.$router.push({ name: this.keyWord, params: { recId: 0 }, query: this.$route.query })
    },

    cancelHandle () {
      this.$router.push({ name: this.keyWord + 's', query: this.$route.query })
    },

    saveHandle (val) {
      if (val) {
        const url = this.keyWord + '/' + (val.id || 0)
        this.$axios.post(url, val)
          .then(response => {
            const { append, insert, data } = response.data
            if (append) {
              this.loadTableData()
            } else if (insert) {
              this.loadTableData()
            } else {
              for (var i = 0; i < this.tableData.length; i++) {
                if (this.tableData[i].id === data.id) {
                  this.$set(this.tableData, i, data)
                  break
                }
              }
            }
            this.postUpdate(data)
            this.cancelHandle()
          })
          .catch(err => {
            console.log(err)
            if (err.response.data.message) {
              this.$notify.error({title: 'Error',  message: err.response.data.message})
            }
          })
      }
    },

    deleteHandle (_id, entity, entity_name) {
      if (_id) {
        const self = this
        let _entity_name = ''
        let _pre_message = ''
        let ending = ''
        if (entity) {
            ending = entity.split(',')[1] || ''
            entity = entity.split(',')[0]
            _entity_name += ` ${entity}`
        }
        if (entity_name) _entity_name += ` "${entity_name}"`
        _entity_name = (_entity_name || '').trim()
        if (_entity_name) {
          _entity_name = _entity_name.charAt(0).toUpperCase() + _entity_name.slice(1)
          _pre_message = `${_entity_name} будет удален${ending}. `
        }
        this.$confirm(
            `${_pre_message}Вы уверены?`,
            'Подтверждение',
            {
              confirmButtonText: 'Да, удалить',
              cancelButtonText: 'Нет, отмена',
              type: 'warning'
            }
        ).then(() => {
            self.$axios.delete(self.keyWord + '/' + _id)
            .then(() => {
              let message = 'Удаление завершено'
              if (_entity_name) {
                  message = `${_entity_name} удален`
              }
              self.$notify.success({message: message})
              self.loadTableData()
            })
            .catch(err => {
              console.log(err)
               self.$notify.error({
                  title: 'Ошибка',
                  message: err.response.data.message || 'Возникла ошибка. Попробуйте позже'
               })
            })

          })
          .catch(() => {
            self.$notify.info({message: 'Удаление отменено'})
          })
      }
    },

    loadTableData () {
      if (this.keyWord) {
        let filters = this.filterParams
        this.$axios.post('/' + this.keyWord, filters)
          .then(response => {
            const { data, total } = response.data
            this.tableData = data || []
            this.totalRecords = total || 0
            this.loading = false
          })
          .catch(err => {
            console.log(err)
            if (err.response.data.message) {
              this.$notify.error({title: 'Error',  message: err.response.data.message})
            }
            this.loading = false
          })
      }
    },

    postUpdate () { }
  }
}
